import { useState } from 'react';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'
import Splash from "./Components/SplashScreen"
import { ThemeProvider } from 'styled-components';
import './App.css';
import { BsTwitter } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
// import "bootstrap/dist/css/bootstrap.min.css";
import Home from './Home';
import Story from './Story';

const LightTheme = {
  pageBackground: "white",
  titleColor: "#dc658b",
  tagLineColor: "black"
};

const DarkTheme = {
  pageBackground: "#282c36",
  titleColor: "lightpink",
  tagLineColor: "lavender"
}

const themes = {
  light: LightTheme,
  dark: DarkTheme,
}

function App() {
  const [theme, setTheme] = useState("dark")
  
  return (
    <BrowserRouter>
    {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark"></nav> */}
    <div style={{backgroundColor: "#282c36"}}>
      <nav className="tw-px-3 md:tw-px-10 tw-z-auto navbar-absolute  navbar navbar-expand-xl navbar-light" style={{width: "100%", paddingTop: "1em", paddingBottom: "1rem"}}>
        <Link to="/"><img src={process.env.PUBLIC_URL+"/image/BW_dark_rect.svg"} className="brand_logo" alt="logo" /></Link>
        <div className="justify-content-end navbar-collapse collapse">
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/story" className="nav-link">Story</Link>
          <Link to="/roadmap" className="nav-link">Roadmap</Link>
          <Link to="/team" className="nav-link">Team</Link>
          <Link to="/merch" className="nav-link">Merch</Link>
          <Link to="/redeem" className="nav-link">Redeem</Link>
          <a href="https://twitter.com/" className="nav-link"><BsTwitter size={20} color={"white"} /></a>
          <a href="https://discord.com/" className="nav-link"><FaDiscord size={20} color={"white"} /></a>
          <a href="https://www.instagram.com/" className="nav-link"><RiInstagramFill size={20} color={"white"} /></a>
        </div>
      </nav>
      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/story" element={<Story/>} />
        <Route path="/Splash" element={<ThemeProvider theme={themes[theme]}><Splash theme={theme} setTheme={setTheme} /></ThemeProvider>} />
      </Routes>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link to="/"><img src={process.env.PUBLIC_URL+"/image/BW_dark_rect.svg"} className="brand_logo" alt="logo" /></Link>
        <div className="justify-content-end navbar-collapse collapse">
          <a href="https://twitter.com/" className="nav-link"><BsTwitter size={20} color={"white"} /></a>
          <a href="https://discord.com/" className="nav-link"><FaDiscord size={20} color={"white"} /></a>
          <a href="https://www.instagram.com/" className="nav-link"><RiInstagramFill size={20} color={"white"} /></a>
        </div>
      </nav>
    </div>
    </BrowserRouter>
  );
}

export default App;
