export default function Story(){
    return(
        <div className="lg:tw-p-8 tw-p-0 max-w-11xl tw-mx-auto" >
            <h1 className=" tw-font-black tw-text-4xl md:tw-text-6xl featured-color md:tw-py-8 tw-py-4 tw-pl-4">Story</h1>
            <div className="grid" style={{position: "relative", width: "100%", height: "100%", overflow: "auto"}}>
                <div style={{position: "relative", width: "100%", height: "790px", overflow: "hidden", minHeight: "100%"}}>
                    <div style={{position: "absolute", willChange: "transform, width, height, opacity", opacity: "1", height: "440px", zIndex: "0", transform: "translate3d(12px, 12px, 0px)", width: "430.333px"}}>
                        <div className="cell" style={{background: "linear-gradient(rgba(0, 0, 0, 0.65), rgba(227, 230, 96, 0.1)), url('/image/ch1.webp')", cursor: "pointer"}}>
                            <div style={{position: "absolute", willChange: "transform, width, height, opacity", opacity: "1", height: "440px", zIndex: "0", transform: "translate3d(12px, 12px, 0px)", width: "451px"}}>
                                <div className="cell" style={{background: "linear-gradient(rgba(0, 0, 0, 0.65), rgba(227, 230, 96, 0.1)), url('/image/ch1.webp')", cursor: "pointer"}}>
                                    <div className="tw-w-full tw-h-full tw-mt-6 tw-ml-4" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)"}}>
                                        <div className="opacity: 1; transform: translate3d(0px, 0px, 0px);">
                                            <div className="order_number tw-text-xl tw-bg-transparent">01</div>
                                            <div className="lg:tw-text-2xl tw-text-3xl tw-text-white tw-mt-6 tw-font-black">The Beginning</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    )
}